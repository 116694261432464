/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as WebApiTypes from '@/modules/api/@types';
import { CONTENT } from '@/modules/constants/pacificleague';
import { ContentConsts } from '@/modules/constants/content';

export const getPlayTimeText = (length: number): string => {
  if (!length) {
    return '-';
  }

  if (!length) {
    return '-';
  }

  if (length <= 60) {
    return `${length}秒`;
  }
  if (length <= 3600) {
    return `${Math.floor(length / 60)}分`;
  }

  const hour = Math.floor(length / 3600);
  const second = length % 3600;

  return second <= 60 ? `${hour}時間` : `${hour}時間${Math.floor(second / 60)}分`;
};

export const isPacificleague = (id: number): boolean => id === CONTENT.ID;

export const changeInningManifestUrl = (url: string, targetfilter: string, currentfilter = ''): string => {
  if (url.includes('inning')) {
    if (!currentfilter) return url;

    return url.replace(currentfilter, targetfilter);
  }

  return url.replace(`filter=`, `filter=${targetfilter};`);
};

type InningInfoType = {
  inningIndex: number;
  location: WebApiTypes.PlLocationType;
};
export const getNextInning = ({ inningIndex, location }: InningInfoType): InningInfoType =>
  location === 'home' ? { inningIndex: inningIndex + 1, location: 'visitor' } : { inningIndex, location: 'home' };

export const getPrevInning = ({ inningIndex, location }: InningInfoType): InningInfoType =>
  location === 'home' ? { inningIndex, location: 'visitor' } : { inningIndex: inningIndex - 1, location: 'home' };

export const getSnsTopTitle = (content: WebApiTypes.WebContentDetail) => {
  let title = '';

  if (!content) {
    return title;
  }

  if (content.parent?.name) {
    if (content.parent.name === content.name_title_short) {
      title += content.name_title_short;
    } else {
      title += content.parent.name + content.name_title_short;
    }
  } else {
    title += 'Rakuten パ・リーグ Special - ライブ・動画配信 | 楽天TV';
  }

  return title;
};

export const getSnsTitle = (content: WebApiTypes.WebContentDetail) => {
  let title = '';

  if (!content) {
    return title;
  }

  if (content.parent?.name) {
    if (content.parent.name === content.name_title_short) {
      title += content.name_title_short;
    } else {
      title += content.parent.name + content.name_title_short;
    }
  } else {
    title += content.name_title;
  }

  return title;
};

export const getSnsDescription = (content: WebApiTypes.WebContentDetail) => {
  let description = '';

  if (!content) {
    return description;
  }

  if (content.comment_long) {
    description += `${content.comment_long.substring(0, 140)}…`;
  } else if (content.comment_short) {
    description += `${content.comment_long.substring(0, 140)}…`;
  }

  return description;
};

export const getDescription = (content: WebApiTypes.WebContentDetail) => {
  let description = '';

  if (!content) {
    return description;
  }

  if (Number(content.item_list?.[0]?.is_free)) {
    description += '【無料動画あり】';
  }

  if (content.parent?.name) {
    if (content.pack_type === ContentConsts.PACK.TYPE.MIX) {
      description += content.name_title_short;
    } else if (content.parent.name === content.name) {
      description += content.name;
    } else if (content.name_title_short.indexOf(content.parent.name) === 0) {
      description += content.name_title_short;
    } else if (content.type === ContentConsts.TYPE.EPISODE.toString()) {
      description += `${content.name_title_short} | ${content.parent.name}`;
    } else {
      description += content.parent.name + content.name_title_short;
    }
  } else {
    description += content.name_title;
  }

  if (content.genre?.name || content.sub_genre.name) {
    if (content.genre?.name) {
      description += `(${content.genre.name}`;
    } else {
      description += '(';
    }

    if (content.sub_genre?.name) {
      description += `-${content.sub_genre.name})`;
    } else {
      description += ')';
    }
  }

  description +=
    'のネット動画配信。あらすじ、キャスト・スタッフ、予告編などの情報もご紹介！動画視聴で楽天ポイントが貯まる楽天TV(Rakuten TV)！';

  return description;
};
