import { useState, useEffect, memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import axios from 'axios';

import { usePlStoreStateContext } from '@/modules/domains/pacificleague/providers';
import Image from '@/modules/components/image';
import Swiper from '@/modules/domains/pacificleague/top-container/components/swiper';
import { TopBannerType } from '@/modules/constants/pacificleague';
import { Logger } from '@/utils';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useRouter } from 'next/router';

dayjs.extend(isBetween);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '12px',
      position: 'relative',
      '& .swiper-slide': {
        width: '320px',
        height: '50px',
      },
      '& .swiper-container': {
        position: 'static',
        [theme.breakpoints.down('sm')]: {
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          'scrollbar-width': 'none',
          '-ms-overflow-style': 'none',
        },
      },
      '& .swiper-button-prev:not(.swiper-button-disabled)': {
        [theme.breakpoints.up('md')]: {
          top: '50%',
          marginTop: '0',
          transform: 'translateY(-50%) rotate(180deg)',
        },
      },
      '& .swiper-button-next:not(.swiper-button-disabled)': {
        [theme.breakpoints.up('md')]: {
          top: '50%',
          marginTop: '0',
          transform: 'translateY(-50%)',
        },
      },
      '& .link': {
        display: 'block',
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_LIVE_URL } = process.env;
const { NEXT_PUBLIC_RTV_WEB_URL } = process.env;
const { NEXT_PUBLIC_IM_URL } = process.env;

type Props = {
  className?: string;
};
const Banner: React.FC<Props> = memo(({ className = '' }) => {
  const classes = useStyles();
  const { debugTime } = usePlStoreStateContext();
  const [banners, setBanners] = useState<Array<TopBannerType>>([]);
  const router = useRouter();

  useEffect(() => {
    axios
      .get('/api/pacificleague/banner/')
      .then((ret: { data: Array<TopBannerType> }) => {
        const now = debugTime ? dayjs(debugTime) : dayjs();
        const isShow = (value: TopBannerType): boolean =>
          (!value.start && !value.end) ||
          (!value.start && now.isBefore(value.end)) ||
          (!value.end && now.isAfter(value.start)) ||
          now.isBetween(value.start, value.end);
        const regex = /^https?:\/{2,}(.*?)(?:\/|\?|#|$)/;
        const changeDomain = (value: TopBannerType): TopBannerType => {
          if (value.link?.match(regex)?.[1] === 'live.tv.rakuten.co.jp') {
            return {
              ...value,
              link: value.link.replace(regex, `${NEXT_PUBLIC_RTV_LIVE_URL}/`),
              img: value.img.replace(regex, `${NEXT_PUBLIC_IM_URL}/`),
            };
          }
          if (value.link?.match(regex)?.[1] === 'tv.rakuten.co.jp') {
            return {
              ...value,
              link: value.link.replace(regex, `${NEXT_PUBLIC_RTV_WEB_URL}/`),
              img: value.img.replace(regex, `${NEXT_PUBLIC_IM_URL}/`),
            };
          }

          return value;
        };
        setBanners(ret?.data?.filter(isShow)?.map(changeDomain));
      })
      .catch((err) => {
        Logger.warn(err);
      });
  }, [debugTime]);

  return (
    <div className={`${className} ${classes.root}`}>
      {banners.length > 0 && (
        <>
          <Swiper spaceBetween={8} slidesPerView="auto" navigation={true} freeMode={true}>
            {banners.map((banner, index) => (
              <a
                className="link"
                href={banner.link}
                key={`${index.toString()}:${banner.alt}`}
                onClick={() => router.push(banner.link).finally(() => null)}
                onKeyDown={() => null}
                role="button"
                tabIndex={0}
              >
                <Image src={banner.img} width={320} height={50} alt={banner.alt} />
              </a>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
});

export default Banner;
