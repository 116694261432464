/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { GetServerSidePropsResult, NextPage } from 'next';
import Layout from '@/modules/layouts/pacificleague';
import * as Device from 'react-device-detect';
import Container from '@/modules/domains/pacificleague/top-container';
import TopStoreProvider from '@/modules/domains/pacificleague/top-container/providers';
import { fetchWebContentDetail } from '@/modules/domains/pacificleague/content-container/providers';
import { CONTENT } from '@/modules/constants/pacificleague';
import { getSnsTopTitle, getSnsDescription } from '@/modules/domains/pacificleague/services';
import * as WebApiTypes from '@/modules/api/@types';
import useGoogleTagManager from '@/modules/analytics/use-google-tag-manager';
import useRestoreScroll from '@/hooks/use-restore-scroll';
import RAT from '@/modules/components/rat';

const { NEXT_PUBLIC_RTV_SPORTS_URL } = process.env;

type Props = {
  contentSSR: WebApiTypes.WebContentDetail;
};
const PacificLeagueTopPage: NextPage<Props> = ({ contentSSR }) => {
  const ratParams = {
    ratSiteSection: 'svod_detail',
    ratPageType: 'shop_item',
    ratPageName: 'content\x3esvod',
    ratItemId: '[252451,252451]',
    ratItemPrice: '[702,5602]',
    ratItemGenre: '',
    ratItemTag: '[]',
    ratAdultFlag: '0',
  };

  const uah: JSONValue = {
    dv_fl: '0',
    lg_fl: '0',
    rg_flag: '0',
    tst_flag: '0',
    cv_register_id: '',
    lg_id: '',
  };

  const dataLayer: JSONValue = {
    sst_svod_content_id: '',
    ecommerce: {
      currencyCode: 'JPY',
      detail: {
        actionField: {
          list: 'SST Detail Page',
        },
        products: [
          {
            name: 'Rakuten パ・リーグSpecial',
            id: '252451',
            content_type: '1',
            category: 'sports',
            variant: '5',
            genre: 'sports',
            sub_genre_id: '149',
            item_type: '',
          },
        ],
      },
    },
  };

  const trackingParam: JSONValue = {
    pageName: 'content\x3esvod',
    channel: 'svod_detail',
    prop2: 'svod',
    prop5: '252451',
    prop8: 'rec_252451',
    prop10: '5',
    prop11: [''],
    prop12: [''],
    prop16: '',
    prop17: '',
    /* Event Variables */
    events: 'prodView,event3',
    /* Conversion Variables */
    products: ';252451',
    eVar1: '',
    eVar5: '',
    eVar6: '',
    eVar16: '',
    eVar17: '',
    eVar2: '',
    eVar24: '',
  };

  const karte: JSONValue = {
    genre: 'pacificleague',
    subGenreId: '149',
    device: Device.isMobile ? 'sp' : 'pc',
    tags: '',
  };

  useGoogleTagManager({ uah, dataLayer, trackingParam, karte });

  useRestoreScroll();

  return (
    <>
      <Layout
        title="パ・リーグ Special - ライブ中継・動画配信"
        snsTitle={getSnsTopTitle(contentSSR)}
        description="パ・リーグのプロ野球試合をライブ中継、動画をいつでも視聴できる楽天TVのサービス！定額見放題で月額とお得な年額プランあり！パ・リーグ開幕戦や公式戦全試合、交流戦（パ・リーグ主催試合）、パ・リーグ クライマックス・シリーズ、一部のオープン戦など、パ・リーグ動画が楽しめます。"
        snsDescription={getSnsDescription(contentSSR)}
        url={`${NEXT_PUBLIC_RTV_SPORTS_URL}/pacificleague/`}
      >
        <TopStoreProvider>
          <Container content={contentSSR} />
        </TopStoreProvider>
      </Layout>
      <RAT ratParams={ratParams} />
    </>
  );
};

export default PacificLeagueTopPage;

export const getServerSideProps = async (): Promise<GetServerSidePropsResult<Props>> => {
  const content = await fetchWebContentDetail(CONTENT.ID);

  return {
    props: {
      contentSSR: content,
    },
  };
};
