import { useCallback, memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import Image from '@/modules/components/image';
import { MULTI_IDS_TEAM, TEAMS } from '@/modules/constants/pacificleague';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      top: '14px',
      right: '20px',
      display: 'flex',
      width: '300px',
      justifyContent: 'space-between',

      '& .btn': {
        display: 'block',
        width: '44px',
        height: '44px',
        border: '2px solid #1d1d1d',
        borderRadius: '50%',
        backgroundColor: '#1d1d1d',
        boxShadow: '0 0 0 0.5px rgb(255 255 255 / 35%)',
        cursor: 'pointer',
        outline: 0,
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',

        '&:hover': {
          borderColor: '#2f2f2f',
          backgroundColor: '#2f2f2f',
        },

        '&.selected': {
          borderColor: '#2c98f0',
        },
      },
      '@media (max-width: 1179px)': {
        top: '68px',
        right: '50%',
        width: '80%',
        boxSizing: 'border-box',
        transform: 'translateX(50%)',
        justifyContent: 'space-around',
      },
      '@media (max-width: 969px)': {
        width: '100%',
        padding: '0 16px',
      },
    },
  }),
);

type IconProps = {
  multiId: number;
  isSelected: boolean;
  onSelect: (selectedMultiId: number) => void;
};

const TeamIcon: React.FC<IconProps> = memo(({ multiId, isSelected, onSelect }) => {
  const team = TEAMS[multiId];

  const onClick = useCallback(() => onSelect(multiId), [multiId, onSelect]);

  return (
    <li className="item">
      <span
        className={`btn ${isSelected ? 'selected' : ''}`}
        onClick={onClick}
        onKeyDown={() => null}
        role="button"
        tabIndex={0}
      >
        <Image src={team.logoUrl} width={40} height={40} alt={team.name} />
      </span>
    </li>
  );
});

type Props = {
  className?: string;
  selectedMultiId: number;
  onChange: (multiId: number) => void;
};

const TeamSelector: React.FC<Props> = memo(({ className = '', selectedMultiId, onChange }) => {
  const classes = useStyles();

  const onSelect = useCallback((multiId: number) => onChange(multiId), [onChange]);

  return (
    <ul className={`${className} ${classes.root}`}>
      {MULTI_IDS_TEAM.map((multiId) => (
        <TeamIcon key={multiId} multiId={multiId} isSelected={selectedMultiId === multiId} onSelect={onSelect} />
      ))}
    </ul>
  );
});

export default TeamSelector;
