import { memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import Image from '@/modules/components/image';

const { NEXT_PUBLIC_IM_URL } = process.env;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .link': {
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        background: '#2d2d2d',
        border: '1px solid #4d4d4d',
        borderRadius: '4px',
        overflow: 'hidden',
        '&:hover': {
          background: 'rgba(255, 255, 255, 0.07)',
        },
        '& .img_cell': {
          '& span,& img': {
            maxHeight: '30px !important',
            maxWidth: '30px !important',
            verticalAlign: 'bottom',
            '@media (max-width: 969px)': {
              maxHeight: '48px !important',
              maxWidth: '48px !important',
            },
          },
        },
        '& .info_cell': {
          padding: '4px 12px',
          fontSize: '13px',
          lineHeight: 1.5,
          '@media (max-width: 969px)': {
            fontSize: '11px',
          },
        },
      },
    },
  }),
);

type Props = {
  className?: string;
};
const Twitter: React.FC<Props> = memo(({ className = '' }) => {
  const classes = useStyles();

  return (
    <div className={`${className} ${classes.root}`}>
      <a className="link" target="_blank" rel="noreferrer noopener" href="https://twitter.com/rakuten_pacific">
        <div className="img_cell">
          <Image
            src={`${NEXT_PUBLIC_IM_URL}/img/pages/pacificleague/thumb_x_black.png`}
            width={160}
            height={160}
            alt="X 公式アカウント"
          />
        </div>
        <p className="info_cell">Xでパ・リーグ情報をご紹介 🎁フォロワー限定プレゼントも 😃ぜひフォローください✨</p>
      </a>
    </div>
  );
});

export default Twitter;
