import { useState, useCallback, memo, useMemo } from 'react';
import { useRouter } from 'next/router';
import { makeStyles, createStyles } from '@mui/styles';
import { Button } from '@mui/material';

import Image from '@/modules/components/image';
import PlanModal from '@/modules/domains/pacificleague/shared/plan-modal';
import { useIsAuthenticated } from '@/modules/auth/providers';

const { NEXT_PUBLIC_IM_URL } = process.env;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .keyVisual': {
        position: 'relative',
        display: 'block',
        height: '40vw',
        width: '100%',
        overflow: 'hidden',
        background: `url(${NEXT_PUBLIC_IM_URL}/static/pacificleague/plg_top/keyVisual.jpg) no-repeat top center / auto 100%`,
        '& .keyVisual_in': {
          position: 'absolute',
          top: '9vw',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          width: '100%',
        },
        '& .keyVisual_copy': {
          width: '100%',
          marginBottom: '-2.3vw',
          '& img': {
            height: '14vw !important',
            width: '100%',
          },
        },
        '& .purchaseBtn': {
          position: 'relative',
          boxSizing: 'border-box',
          width: '18vw',
          backgroundColor: '#f24848',
          lineHeight: 1,
          fontFamily: '"ヒラギノ角ゴシック",Hiragino sans-serif',
          '&:hover': {
            backgroundColor: '#f55',
          },
          borderRadius: '8px',
          padding: '16px',
          margin: '0 auto',
          textAlign: 'center',
          cursor: 'pointer',
          boxShadow: '0 0 20px 8px rgba(0, 0, 0, .8)',
          '&_in': {
            fontSize: '20px',
            fontWeight: '700',
            whiteSpace: 'nowrap',
            color: '#fff',
            '@media (max-width: 1179px)': {
              fontSize: '18px',
            },
          },
        },
        '& .plHead_loginWrap': {
          display: 'none',
        },
        '@media (max-width: 969px)': {
          height: '86vw',
          backgroundSize: 'auto 68%',
          '& .keyVisual_in': {
            top: '19vw',
          },
          '& .keyVisual_copy': {
            width: '100%',
            marginBottom: '-7.5vw',
            '& span img': {
              height: '44vw !important',
              width: '100%',
            },
          },
          '& .purchaseBtn': {
            display: 'inline-block',
            width: '60%',
            padding: '4vw 0',
            '& .purchaseBtn_in': {
              fontSize: 'calc(8px + 3vw)',
            },
          },
          '& .plHead_loginWrap': {
            display: 'block',
            marginTop: '2.5vw',
            textAlign: 'center',
            '& .plHead_login': {
              marginTop: 0,
              display: 'inline-block',
              fontSize: '11px',
              color: '#aaa',
              textDecoration: 'underline',
            },
          },
        },
      },
    },
    header: {
      position: 'relative',

      '& .keyVisualCopy': {
        position: 'absolute',
        top: '9vw',
        left: '50%',
        transform: 'translateX(-50%)',
      },

      '& .plan': {
        background: '#BF0000',
        color: '#ffffff',
        display: 'block',
        width: '18vw',
        margin: '0 auto',
        marginTop: '-2.3vw',
        fontSize: '20px',
        fontWeight: '700',
      },
    },
  }),
);

const { NEXT_PUBLIC_RTV_SPORTS_URL } = process.env;
const { NEXT_PUBLIC_APP_ENV } = process.env;
const { NEXT_PUBLIC_RTV_AUTH_URL } = process.env;
const LOGIN_URL = `${NEXT_PUBLIC_RTV_AUTH_URL ?? ''}/gw/rakuten/pre_login?bu=`;
const LOCAL_LOGIN_URL = '/dev/login?bu=';

type Props = {
  className?: string;
};
const Header: React.FC<Props> = memo(({ className = '' }) => {
  const classes = useStyles();
  const router = useRouter();
  const isAuthenticated = useIsAuthenticated();
  const [isOpenPlanModal, setIsOpenPlanModal] = useState<boolean>(false);

  const onClickPlan = useCallback(() => {
    setIsOpenPlanModal(true);
  }, []);

  const onClosePlanModal = useCallback(() => {
    setIsOpenPlanModal(false);
  }, []);

  const loginUrl = useMemo(() => {
    const returnUrl = `${NEXT_PUBLIC_RTV_SPORTS_URL}${router.asPath}`;
    const url = `${NEXT_PUBLIC_APP_ENV === 'development' ? LOCAL_LOGIN_URL : LOGIN_URL}${encodeURIComponent(
      returnUrl,
    )}`;

    return url;
  }, [router]);

  return (
    <>
      <header className={`${className} ${classes.root}`}>
        <div className="keyVisual">
          <div className="keyVisual_in">
            <p className="keyVisual_copy">
              <Image src="/images/pacificleague/keyVisualCopy.png" width={962} height={420} />
            </p>
            <Button className="purchaseBtn" data-gtmval="general" onClick={onClickPlan}>
              <span className="purchaseBtn_in">料金プランを見る</span>
            </Button>
            {!isAuthenticated && (
              <div className="plHead_loginWrap">
                <a href={loginUrl} className="plHead_login">
                  <span className="plHead_btn_txt2">購入済みの方は</span>ログイン
                </a>
              </div>
            )}
          </div>
        </div>
      </header>
      <PlanModal isOpen={isOpenPlanModal} onClose={onClosePlanModal} />
    </>
  );
});

export default Header;
