import { memo, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import {
  useTopStoreStateContext,
  useTopStoreDispatchContext,
  TopStoreActionTypes,
} from '@/modules/domains/pacificleague/top-container/providers';
import ShareSns from '@/modules/domains/pacificleague/shared/share-sns';
import FavoriteButton from '@/modules/domains/pacificleague/shared/favorite-button';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      position: 'sticky',
      bottom: 0,
      zIndex: 11,
      margin: '16px 16px 0 0',
      paddingBottom: '16px',

      '& .favorite': {
        marginLeft: '8px',
        outline: 0,
        '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
      },
    },
  }),
);

type Props = {
  className?: string;
};
const FloatButtons: React.FC<Props> = memo(({ className = '' }) => {
  const classes = useStyles();
  const dispatch = useTopStoreDispatchContext();
  const { isBookmarked } = useTopStoreStateContext();

  const onChangeFavorite = useCallback(
    (isFavorite: boolean) =>
      dispatch({ type: isFavorite ? TopStoreActionTypes.SET_BOOKMARK : TopStoreActionTypes.DELETE_BOOKMARK }),
    [dispatch],
  );

  return (
    <div className={`${className} ${classes.root}`}>
      <ShareSns />
      <FavoriteButton className="favorite" isFavorite={isBookmarked} onChange={onChangeFavorite} />
    </div>
  );
});

export default FloatButtons;
