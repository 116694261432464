/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { memo, useEffect, useCallback } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { useRouter } from 'next/router';

import { useIsAuthenticated } from '@/modules/auth/providers';
import { CONTENT } from '@/modules/constants/pacificleague';
import webApi from '@/modules/api/web-api';

const { NEXT_PUBLIC_APP_ENV } = process.env;
const { NEXT_PUBLIC_RTV_AUTH_URL } = process.env;
const LOGIN_URL = `${NEXT_PUBLIC_RTV_AUTH_URL ?? ''}/gw/rakuten/pre_login?bu=`;
const LOCAL_LOGIN_URL = '/dev/login?bu=';

type StyleProps = {
  isFavorite: boolean;
};
const useStyles = makeStyles<Theme, StyleProps>((_theme: Theme) =>
  createStyles({
    root: {
      '& .iconBase': {
        position: 'relative',
        display: 'flex',
        backgroundColor: 'rgba(255,255,255,.8)',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 8px 12px #000',
        backdropFilter: 'saturate(2) blur(4px)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        cursor: 'pointer',

        '& .iconInner': {
          display: 'none',
          width: '24px',
          height: '24px',

          '&.iconOff': {
            background:
              'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0D%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M16.1547%2017.0671C14.4979%2018.6413%2012.8239%2019.8533%2011.8714%2020.4991C10.9332%2019.8331%209.28544%2018.5851%207.66319%2016.9763C5.51669%2014.8478%202.96594%2011.6303%203.00044%208.39333C3.02519%206.08408%204.90844%204.22558%207.19894%204.25033C8.66594%204.26608%209.98744%205.04158%2010.7344%206.32558L11.9997%208.49983L13.3107%206.35333C14.0854%205.08583%2015.4234%204.33808%2016.8897%204.35383C19.1802%204.37858%2021.0237%206.27683%2020.9997%208.58533C20.9644%2011.8231%2018.3454%2014.9851%2016.1547%2017.0671M16.9062%202.85382C14.8459%202.83207%2013.0369%203.92557%2012.0312%205.57107C11.0607%203.90382%209.27494%202.77207%207.21469%202.75032C4.09244%202.71732%201.53419%205.23657%201.50044%208.37757C1.42169%2015.6893%2011.8527%2022.3013%2011.8527%2022.3013C11.8527%2022.3013%2022.4209%2015.9136%2022.4989%208.60107C22.5327%205.46007%2020.0284%202.88757%2016.9062%202.85382%22%20fill%3D%22black%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A) no-repeat center center',
            display: (props: StyleProps) => (props.isFavorite ? 'none' : 'block'),
          },

          '&.iconOn': {
            background:
              'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0D%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M22.5%208.43725C22.5%205.297%2019.9688%202.75%2016.8465%202.75C14.7855%202.75%2012.9878%203.86225%2012%205.519C11.0122%203.86225%209.2145%202.75%207.1535%202.75C4.03125%202.75%201.5%205.297%201.5%208.43725C1.5%2015.7498%2012%2022.25%2012%2022.25C12%2022.25%2022.5%2015.7498%2022.5%208.43725%22%20fill%3D%22%232C98F0%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A) no-repeat center center',
            display: (props: StyleProps) => (props.isFavorite ? 'block' : 'none'),
            animation: `$favoriteOn 0.5s 1 ease-out`,
          },
        },
      },

      '&:hover': {
        '& .iconBase': {
          backgroundColor: 'rgba(255,255,255,1)',
          backdropFilter: 'saturate(2) blur(4px)',
        },
      },

      '& .iconBaseOn': {
        backgroundColor: '#fff',
      },
    },
    '@keyframes favoriteOn': {
      '25%': { transform: 'scale(1.5)' },
      '50%': { transform: 'scale(1)' },
      '75%': { transform: 'scale(1.2)' },
      '100%': { transform: 'scale(1)' },
    },
  }),
);

type Props = {
  className?: string;
  isFavorite?: boolean;
  contentId?: number;
  onChange?: (_: boolean) => void;
};
const FavoriteButton: React.FC<Props> = memo(
  ({ className = '', isFavorite = false, contentId = CONTENT.ID, onChange = () => null }) => {
    const classes = useStyles({ isFavorite });
    const isAuthenticated = useIsAuthenticated();
    const router = useRouter();

    const setBookmark = useCallback(() => {
      webApi()
        .web.bookmark.post({
          body: {
            content_id: contentId,
          },
          config: {
            withCredentials: true,
          },
        })
        .then((result) => {
          if (result?.body?.status !== 'success') {
            throw new Error();
          }
          onChange(true);
        })
        .catch(() => null);
    }, [contentId, onChange]);

    const removeBookmark = useCallback(() => {
      webApi()
        .web.bookmark.deleteByContentId.delete({
          body: {
            content_id: contentId,
          },
          config: {
            withCredentials: true,
          },
        })
        .then((result) => {
          if (result?.body?.status !== 'success') {
            throw new Error();
          }
          onChange(false);
        })
        .catch(() => null);
    }, [contentId, onChange]);

    const onClick = useCallback(() => {
      if (!isAuthenticated) {
        const returnUrl = new URL(window?.location?.href);
        returnUrl.searchParams.set('bookmark', contentId.toString());
        const urlLogin = `${NEXT_PUBLIC_APP_ENV === 'development' ? LOCAL_LOGIN_URL : LOGIN_URL}${encodeURIComponent(
          returnUrl.toString(),
        )}`;
        router.push(urlLogin).finally(() => null);

        return;
      }

      if (isFavorite) {
        removeBookmark();
      } else {
        setBookmark();
      }
    }, [contentId, isAuthenticated, isFavorite, removeBookmark, router, setBookmark]);

    // 非ログイン時にお気に入りを行った場合のログイン画面からのリダイレクト時の処理
    // 判定：contentの取得完了 かつ bookmarkパラメータあり(content.idが一致)
    useEffect(() => {
      if (!contentId) {
        return;
      }

      const { bookmark } = router.query;
      if (bookmark !== contentId.toString()) {
        return;
      }

      // URLからbookmarkパラメータを削除
      const url = new URL(window?.location?.href);
      url.searchParams.delete('bookmark');
      const newUrl = url.toString();
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, undefined, newUrl);

      setBookmark();
    }, [router, contentId, setBookmark]);

    return (
      <div
        className={`${className} ${classes.root}`}
        onClick={onClick}
        onKeyDown={() => null}
        role="button"
        tabIndex={0}
      >
        <span className={`iconBase ${isFavorite ? 'iconBaseOn' : ''}`}>
          <span className="iconInner iconOff" />
          <span className="iconInner iconOn" />
        </span>
      </div>
    );
  },
);

export default FavoriteButton;
