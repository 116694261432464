import { useState, useEffect, useMemo, useCallback, useRef, memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import SwiperCore from 'swiper/core';

import UnitTitle from '@/modules/domains/pacificleague/top-container/unit-title';
import TeamSelector from '@/modules/domains/pacificleague/top-container/team-selector';
import Element from '@/modules/domains/pacificleague/top-container/element';
import Swiper from '@/modules/domains/pacificleague/top-container/components/swiper';
import { MULTI_ID_TEAM_RAKUTEN } from '@/modules/constants/pacificleague';
import * as WebApiTypes from '@/modules/api/@types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      paddingTop: '28px',
      '& .swiperWrap': {
        position: 'relative',
        margin: '12px 0 0 -8px',
        '@media (max-width: 1179px)': {
          marginTop: '66px',
        },
      },
      '& .unit_elm': {
        '& .swiper-slide': {
          width: '240px',
        },
      },
      '& .swiper-container': {
        position: 'static',
        [theme.breakpoints.down('sm')]: {
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          'scrollbar-width': 'none',
          '-ms-overflow-style': 'none',
        },
      },
    },
  }),
);

type Props = {
  className?: string;
  units: WebApiTypes.Unit[];
};
const TeamSelectorUnit: React.FC<Props> = memo(({ className = '', units }) => {
  const classes = useStyles();
  const [selectedMultiId, setSelectedMultiId] = useState<number>(MULTI_ID_TEAM_RAKUTEN);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const cachedIndex = useRef<{ [key: number]: number }>({});

  const selectedUnit = useMemo(
    () => units.find((unit) => Number(unit.multi_id) === selectedMultiId),
    [selectedMultiId, units],
  );

  const onChangeTeam = useCallback((multiId: number) => {
    setSelectedMultiId(multiId);
    localStorage.setItem('teamID', multiId.toString());
  }, []);

  const onSwiperSlideChanged = useCallback(
    (s: SwiperCore) => {
      cachedIndex.current[selectedMultiId] = s.activeIndex;
    },
    [selectedMultiId],
  );

  useEffect(() => {
    const cachedMultiId = Number(localStorage.getItem('teamID'));
    if (cachedMultiId) {
      setSelectedMultiId(cachedMultiId);
    }
  }, []);

  useEffect(() => {
    if (!swiper) {
      return;
    }

    if (cachedIndex.current?.[selectedMultiId] === undefined) {
      swiper.slideTo(0);
    } else {
      swiper.slideTo(cachedIndex.current[selectedMultiId]);
    }
  }, [selectedMultiId, swiper]);

  return (
    <section className={`lSec ${className} ${classes.root}`}>
      <TeamSelector selectedMultiId={selectedMultiId} onChange={onChangeTeam} />
      <UnitTitle unit={selectedUnit} />
      <div className="swiperWrap">
        <Swiper
          className="unit_elm"
          navigation={true}
          slidesPerView="auto"
          freeMode={true}
          breakpoints={{
            '1024': {
              slidesPerGroup: 3,
            },
            '1280': {
              slidesPerGroup: 4,
            },
            '1440': {
              slidesPerGroup: 5,
            },
          }}
          onSwiper={setSwiper}
          onSlideChange={onSwiperSlideChanged}
        >
          {selectedUnit?.element_list?.slice(0, 17)?.map((element) => (
            <Element key={element.id} unit={selectedUnit} element={element} />
          ))}
        </Swiper>
      </div>
    </section>
  );
});

export default TeamSelectorUnit;
