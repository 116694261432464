import { useMemo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

import { usePlStoreStateContext } from '@/modules/domains/pacificleague/providers';
import { useTopStoreStateContext } from '@/modules/domains/pacificleague/top-container/providers';
import { UNIT_TYPE_SEARCH_TAG, UNIT_ID_VOD, MULTI_ID_LIVE, MULTI_IDS_TEAM } from '@/modules/constants/pacificleague';
import Header from '@/modules/domains/pacificleague/top-container/header';
import Information from '@/modules/domains/pacificleague/top-container/information';
import Twitter from '@/modules/domains/pacificleague/top-container/twitter';
import Banner from '@/modules/domains/pacificleague/top-container/banner';
import Unit from '@/modules/domains/pacificleague/top-container/unit';
import TeamSelectorUnit from '@/modules/domains/pacificleague/top-container/team-selector-unit';
import FloatButtons from '@/modules/domains/pacificleague/top-container/float-buttons';
import * as WebApiTypes from '@/modules/api/@types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    alert: {
      padding: '12px',
      borderRadius: '4px',
      color: '#ff9898',
      backgroundColor: 'rgba(242, 72, 72, 0.3)',
      textAlign: 'center',
      fontSize: '12px',
      lineHeight: 1.6,
      whiteSpace: 'pre-wrap',
    },
    header: {
      '& + .lSec': {
        position: 'relative',
        marginTop: '-12vw',
        '@media (max-width: 1179px)': {
          marginTop: '-11vw',
        },
        '@media (max-width: 969px)': {
          marginTop: '-4vw',
        },
      },
    },
    topBkg: {
      background: 'url(/images/pacificleague/bg.jpg) no-repeat top right/80% auto',
      '@media (max-width: 969px)': {
        backgroundPosition: '30% 0',
        backgroundSize: '200% auto',
      },
      '@media (min-width: 970px)': {
        backgroundAttachment: 'fixed',
      },
      '& $alert': {
        marginBottom: '10px',
      },
    },
    information: {
      whiteSpace: 'pre-wrap',
    },
    twitter: {
      marginTop: '20px',
    },
    banner: {
      marginTop: '20px',
    },
  }),
);
type Props = {
  content?: WebApiTypes.WebContentDetail;
};
const Container: React.FC<Props> = ({ content }) => {
  const classes = useStyles();
  const { purchaseInfo, isFetched } = usePlStoreStateContext();
  const { units } = useTopStoreStateContext();

  const unitLive = useMemo(
    () =>
      units.find(
        (unit) =>
          Number(unit?.type) === UNIT_TYPE_SEARCH_TAG &&
          Number(unit?.multi_id) === MULTI_ID_LIVE &&
          unit?.element_list?.length > 0,
      ),
    [units],
  );

  const unitVod = useMemo(
    () => units.find((unit) => Number(unit?.id) === UNIT_ID_VOD && unit?.element_list?.length > 0),
    [units],
  );

  const unitsTeam = useMemo(() => units.filter((unit) => MULTI_IDS_TEAM.includes(Number(unit?.multi_id))), [units]);

  const unitsOther = useMemo(
    () => units.filter((unit) => unit !== unitLive && unit !== unitVod && !unitsTeam.includes(unit)),
    [unitLive, unitVod, units, unitsTeam],
  );

  return (
    <div className={`${classes.container} ${purchaseInfo ? classes.topBkg : ''}`}>
      {content?.alert && <p className={classes.alert}>{content.alert.replace(/\r\n/g, '\n')}</p>}
      {isFetched && (
        <>
          {purchaseInfo ? null : <Header className={classes.header} />}
          <section className="lSec">
            <Information className={classes.information} text={content?.information} />
            <Twitter className={classes.twitter} />
            <Banner className={classes.banner} />
          </section>
          {unitLive?.element_list?.length > 0 ? <Unit unit={unitLive} /> : null}
          {unitVod?.element_list?.length > 0 ? <Unit unit={unitVod} /> : null}
          {unitsTeam?.length > 0 ? <TeamSelectorUnit units={unitsTeam} /> : null}
          {unitsOther.map((unit) => (
            <Unit unit={unit} key={unit.id} />
          ))}
          <FloatButtons />
        </>
      )}
    </div>
  );
};

export default Container;
