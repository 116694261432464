import { useState, useCallback, memo } from 'react';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      backgroundColor: 'transparent',
      outline: 'none',
      border: '1px solid #4d4d4d',
      borderRadius: '5px',
      cursor: 'pointer',
      padding: '10px 14px',
      appearance: 'none',
      textAlign: 'left',
      '& .information_in': {
        color: '#aaa',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        height: '3.2em',
        lineHeight: 1.55,
        fontSize: '12px',
      },
      '& .information_ttl': {
        display: 'inline',
        fontWeight: 700,
        color: '#fff',
        '&::after': {
          content: "'：'",
          color: '#aaa',
          fontWeight: '400',
        },
      },
      '& .information_txt': {
        display: 'inline',
        '& br': {
          display: 'none',
        },
      },
      '& .icon-forward': {
        position: 'absolute',
        right: '4px',
        bottom: 0,
        transform: 'rotate(90deg)',
        width: '20px',
        height: '20px',
        background:
          'url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22white%22%20d%3D%22M9.235%205L7.59%206.645L12.9333%2012L7.59%2017.355L9.235%2019L16.235%2012L9.235%205Z%22%20%2F%3E%3C%2Fsvg%3E) no-repeat center center',
      },
      '&:hover': {
        backgroundColor: 'rgba(256,256,256,.05)',
      },
      '&.open .information_in': {
        display: 'block',
        height: 'auto',
      },
      '&.open .information_ttl': {
        display: 'block',
        marginBottom: '0.6em',
        '&::after': {
          display: 'none',
        },
      },
      '&.open .information_txt': {
        '& br': {
          display: 'block',
        },
      },
      '&.open .icon-forward': {
        transform: 'rotate(-90deg)',
      },
    },
  }),
);

type Props = {
  text: string;
  className?: string;
};
const Information: React.FC<Props> = memo(({ text, className = '' }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <button type="button" className={`${className} ${classes.root} ${isOpen ? 'open' : ''}`} onClick={onClickToggle}>
      <div className="information_in">
        <p className="information_ttl">Information</p>
        <p className="information_txt">{text?.replace(/\r\n/g, '\n')}</p>
      </div>
      <span className="icon-forward" />
    </button>
  );
});

export default Information;
